<template>
    <div class="overflow-auto">
        <b-row>
            <b-col cols="12">
                <b-row no-gutters class="">
                    <!-- destinations -->
                    <b-col class="" cols="12">
                        <!-- name, subtitle -->
                        <div class="flex-start align-bottom" style="display: inline">
                            <h2 class="" style="display: inline">
                                {{getName}}
                            </h2>
                            <!-- subtitle -->
                            <h5 class="text-secondary" style="display: inline">
                                {{getSubtitle}}
                            </h5>
                        </div>
                        <!-- reviews -->
                        <b-card-text class="text-primary mt-0 align-content-start" style="display: flex">
                            <div class="rating-stars">
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <!--                                            <b-icon-star-half></b-icon-star-half>-->
                                <!--                                            <b-icon-star></b-icon-star>-->
                            </div>
                            <div class="rating-reviews">
                                (325 reviews)
                            </div>
                        </b-card-text>
                        <!-- languages -->
                        <b-card-text>
                            <p class="mb-2">
                                <b class="text-secondary">
                                    {{$tc('form.language', 0)}}:
                                </b>
                                <span v-for="(language, index) in getLanguages">
                                    <i v-if="index >= getLanguages.length -1">
                                         {{`${language}`}}
                                    </i>
                                    <i v-else>
                                         {{`${language},`}}
                                    </i>
                                </span>
                            </p>
                        </b-card-text>
                    </b-col>
                    <!-- collage pictures -->
                    <b-col class="destination-collage" cols="12">
                        <b-row no-gutters>
                            <b-img class="destination-img" no-body v-for="(picture, index) in getPictures"
                                   :src="picture.image.src_p"></b-img>
                        </b-row>
                    </b-col>
                    <!-- destinations tabs -->
                    <b-row class="mt-2">
                        <b-col>
                            <b-card class="mb-2" no-body>
                                <b-tabs card fill pills>
                                    <!-- tab introduction -->
                                    <b-tab class="destination-tabs">
                                        <template #title>
                                            <b-icon
                                                class="mr-1"
                                                :icon="!__isNull(getDescription) ? 'b-icon-house-fill' : 'b-icon-house'"
                                            ></b-icon>
                                            {{$t('adventure.destination.tab.description')}}
                                        </template>
                                        <div class="p-0" v-html="getDescription"></div>
                                    </b-tab>
                                    <!-- tab host -->
                                    <b-tab class="destination-tabs">
                                        <template #title>
                                            <b-icon
                                                class="mr-1"
                                                :icon="!__isNull(getHost) ? 'b-icon-person-fill' : 'b-icon-person'"
                                            ></b-icon>
                                            {{$t('adventure.destination.tab.host')}}
                                        </template>
                                        <div class="p-0" v-html="getHost">
                                        </div>
                                    </b-tab>
                                    <!-- tab address -->
                                    <b-tab class="destination-tabs">
                                        <template #title>
                                            <b-icon
                                                class="mr-1"
                                                :icon="!__isNull(getAddress) ? 'b-icon-geo-alt-fill' : 'b-icon-geo-alt'"
                                            ></b-icon>
                                            {{$t('adventure.destination.tab.address')}}
                                        </template>
                                        <div v-html="getAddress"></div>
                                    </b-tab>
                                    <!-- tab contact -->
                                    <b-tab class="destination-tabs">
                                        <template #title>
                                            <b-icon
                                                variant="danger"
                                                class="mr-1"
                                                icon="b-icon-envelope-fill"
                                            ></b-icon>
                                            {{$t('adventure.destination.tab.contact')}}
                                        </template>
                                        <!--                                                                                <div class="p-0" v-html="getIntroduction">
                                                                                                                        </div>-->
                                        <b-form-group
                                            :label="$t('adventure.destination.message_label') + ':'"
                                        >
                                            <b-form-textarea
                                                size="sm"
                                                type="text"
                                                v-model="messageText"
                                                id="messageText"
                                            ></b-form-textarea>
                                        </b-form-group>
                                    </b-tab>
                                    <!-- tab reviews -->
                                    <b-tab class="destination-tabs">
                                        <template #title>
                                            <b-icon
                                                variant="danger"
                                                class="mr-1"
                                                :icon="'b-icon-star-fill'"
                                            ></b-icon>
                                            {{$t('adventure.destination.tab.reviews')}}
                                        </template>
                                        <!--                                                                                <div class="p-0" v-html="getIntroduction">-->
                                        <!--                                                                                </div>-->
                                        <h5 class="text-primary">
                                            Giselle (Francija)

                                            <b-icon-star-fill></b-icon-star-fill>
                                            <b-icon-star-fill></b-icon-star-fill>
                                            <b-icon-star-fill></b-icon-star-fill>
                                            <b-icon-star-fill></b-icon-star-fill>
                                            <b-icon-star-fill></b-icon-star-fill>
                                        </h5>
                                        The garden is amazing! Nice people, I would definitely go there again. Thank you
                                        for the experience that made my stay in Slovenia all the more fantastic.
                                    </b-tab>
                                    <!-- tab notes -->
                                    <b-tab class="destination-tabs" v-if="!__isNull(getNotes)">
                                        <template #title>
                                            <b-icon
                                                class="mr-1"
                                                icon="b-icon-file-earmark-text-fill"
                                            ></b-icon>
                                            {{$t('adventure.destination.tab.notes')}}
                                        </template>
                                        <div class="p-0" v-html="getNotes">
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from "vue";

//@group Form.ADVENTURE
export default {
    name: "ElementPopupDestination",
    components: {},
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            componentKey: null,
            slide: 0,
            sliding: null,
            value: '',
            experienceDate: null,
            experienceGuests: null,
            guests: {
                adult: 0,
                child: 0,
                toddler: 0,
                senior: 0,
                schoolchild: 0,
                escort: 0,
            },
            messageEmail: '',
            messageText: '',
            // <-- modal experienceBooking -->
            modalBus: new Vue()
        }
    },
    mounted() {
    },
    methods: {
        // <-- carousel -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        addExperienceToAdventure(marker) {
            // <-- get select if experience is already in adventure -->
            let select = false;
            this.adventures.every(adventure => {
                if (adventure.id === marker.id) {
                    select = adventure.select;
                    return false;
                }
                return true;
            });
            // <-- add experience to adventure -->
            this.adventures.push(
                {
                    key: this.__randomKey(),
                    select: select,
                    id: marker.id,
                    title: marker.title,
                    description: marker.description,
                    // experienceId: marker.experience.id
                }
            );
        },
    },
    computed: {
        getName() {
            if (this.__isNull(this.record.meta) || this.__isNull(this.record.locale)) {
                return null;
            }
            // <--  -->
            let name = this.record.meta.name;
            if (!this.__isNull(this.record.locale.name)) {
                name = this.record.locale.name;
            }
            // <--  -->
            name += this.__isNull(this.record.locale.subtitle) ? '' : ':';
            return name;
        },
        getSubtitle() {
            if (this.__isNull(this.record.meta) || this.__isNull(this.record.locale)) {
                return null;
            }
            // <--  -->
            let subtitle = this.record.meta.subtitle;
            if (!this.__isNull(this.record.locale.subtitle)) {
                subtitle = this.record.locale.subtitle;
            }
            // <--  -->
            return subtitle;
        },
        getPictures() {
            if (this.__isNull(this.record.pictures)) {
                return null;
            }
            let maxPicturesNumber = 8;
            let picturesNumber = 4;
            // <-- destinations pictures -->
            let pictures = this.__clone(this.record.pictures);
            pictures.forEach(picture => {
                picture.image.src = this.__getImageSrc(picture.image.src);
                picture.image.src_p = this.__getImageSrc(picture.image.src_p);
            });
            // <-- experiences pictures -->
            let experiences = this.__clone(this.record.experiences);
            let loop = true;
            do {
                loop = false;
                experiences.forEach(experience => {
                    experience.pictures.every(picture => {
                        pictures.push({
                            image: {
                                src: this.__getImageSrc(picture.image.src),
                                src_p: this.__getImageSrc(picture.image.src_p)
                            }
                        });
                        experience.pictures.splice(0, 1);
                        loop = experience.pictures.length > 0 ? true : loop;
                        return false;
                    });
                });
                loop = pictures.length >= maxPicturesNumber ? false : loop;
            } while (loop)
            // <--  -->
            return pictures.slice(0, picturesNumber);
        },
        getDescription() {
            if (this.__isNull(this.record.locale)) {
                return null;
            }
            return this.__removeParagrafFromText(this.record.locale.description);
        },
        getHost() {
            if (this.__isNull(this.record.locale)) {
                return null;
            }
            return this.__removeParagrafFromText(this.record.locale.host);
        },
        getLanguages() {
            if (this.__isNull(this.record.languagesTour)) {
                return null;
            }
            return this.record.languagesTour;
        },
        getAddress() {
            if (this.__isNull(this.record.address)) {
                return null;
            }
            // <--  -->
            let address = '';
            address += this.__isNull(this.record.address.street) ? '' : this.record.address.street + '<br>';
            address += this.__isNull(this.record.address.postOffice) ? '' : this.record.address.postOffice + '<br>';
            address += this.__isNull(this.record.address.state) ? '' : this.record.address.state + '<br>';
            address += this.__isNull(this.record.address.region) ? '' : this.record.address.region + '<br>';
            address += this.__isNull(this.record.address.continent) ? '' : this.record.address.continent;
            // <--  -->
            return address;
        },
        getNotes() {
            if (this.__isNull(this.record.locale) || this.__isNull(this.record.locale.notes)) {
                return null;
            }
            return this.__removeParagrafFromText(this.record.locale.notes);
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
