<template>
    <div>
        <b-modal
            :id="$options.name  + this.componentKey"
            :cancel-title="$t('form.cancel')"
            :ok-title="$t('form.save')"
            header-bg-variant="primary"
            size="lg"
            :title="$t('adventure.experience.modal_title')"
        >
            <!--            @ok="addExperienceToAdventure(marker)"-->
            <!--            :scrollable="!getRecords > 3"-->

            <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
                <div class="p-2">
                    <b-row>
                        <b-col cols="12" sm="8" lg="6" md="8" class="">
                            <h5>1. {{$t('adventure.experience.date')}}:</h5>
                            <b-form-datepicker
                                id="date"
                                variant="primary"
                                size="sm"
                            >
                            </b-form-datepicker>
                        </b-col>
                        <b-col cols="12" sm="4" lg="6" md="4" class="mt-2 mt-sm-0">
                            <h5>2. {{$t('adventure.experience.time')}}:</h5>
                            <b-form-group>
                                <b-form-input
                                    type="time"
                                    size="sm"
                                    style="width: 100px"
                                    class="text-center"
                                ></b-form-input>
                            </b-form-group>
                            <!-- TODO Stojan: alternatively, if session is available instead of time -->
                            <h5>2. {{$t('adventure.experience.session')}}:</h5>
                            <b-button
                                size="sm"
                                pill
                                variant="white"
                                class="border-primary mr-2 mb-2"
                            >
                                <!--                            variant="white" -->
                                <!--TODO onselect variant="secondary"-->
                                14:00-16:00
                            </b-button>
                        </b-col>
                    </b-row>
                    <!-- guests -->
                    <b-row class="my-4">
                        <b-col cols="12">
                            <h5>3. {{$t('adventure.experience.guests')}}: aaa</h5>
                        </b-col>
                        <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                            <b-form-group
                                :label="$tc('adventure.experience.adult', 1) + ' (13+):'"
                            >
                                <b-form-spinbutton
                                    size="sm"
                                    id="guestsAdult" v-model="guests.adult" min="0"
                                >
                                </b-form-spinbutton>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                            <b-form-group
                                :label="$tc('adventure.experience.child', 1) + ' (3-12):'"
                            >
                                <b-form-spinbutton
                                    size="sm"
                                    id="guestsChild" v-model="guests.child" min="0"
                                >
                                </b-form-spinbutton>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                            <b-form-group
                                :label="$tc('adventure.experience.toddler', 1) + ' (0-2):'"
                            >
                                <b-form-spinbutton
                                    size="sm"
                                    id="guestsToddler" v-model="guests.toddler" min="0"
                                >
                                </b-form-spinbutton>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                            <b-form-group
                                :label="$tc('adventure.experience.senior', 1) + ' (65+):'"
                            >

                                <b-form-spinbutton
                                    size="sm"
                                    id="guestsSenior" v-model="guests.senior" min="0"
                                >
                                </b-form-spinbutton>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button
                                size="sm"
                                variant="secondary"
                                v-b-toggle="'collapse-guests'"
                            >
                                ...
                            </b-button>
                            <b-collapse id="collapse-guests">
                                <b-row class="mt-3">
                                    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                        <b-form-group
                                            :label="$tc('adventure.experience.schoolchild', 1)"
                                        >
                                            <b-form-spinbutton
                                                size="sm"
                                                id="guestsSchoolchild" v-model="guests.schoolchild" min="0"
                                            >
                                            </b-form-spinbutton>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                        <b-form-group
                                            :label="$tc('adventure.experience.escort', 1)"
                                        >
                                            <b-form-spinbutton
                                                size="sm"
                                                id="guestsEscort" v-model="guests.escort" min="0"
                                            >
                                            </b-form-spinbutton>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                        <b-form-group
                                            :label="$tc('adventure.experience.bus_driver', 1)"
                                        >
                                            <b-form-spinbutton
                                                size="sm"
                                                id="guestsDriver" v-model="guests.driver" min="0"
                                            >
                                            </b-form-spinbutton>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-collapse>
                        </b-col>
                    </b-row>
                    <!-- language -->
                    <b-row class="">
                        <b-col cols="12">
                            <h5>4. {{$t('adventure.experience.language')}}:</h5>
                        </b-col>
                        <b-col cols="12">
                            <b-button
                                size="sm"
                                pill
                                variant="white"
                                class="border-primary"
                            >
                                <!--                            variant="white" -->
                                <!--TODO onselect variant="secondary"-->
                                slovenščina
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "ModalExperienceBooking",
    components: {},
    props: {
        componentKey: {
            type: String,
            default: ''
        },
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            experienceDate: null,
            experienceGuests: null,
            guests: {
                adult: 0,
                child: 0,
                toddler: 0,
                senior: 0,
                schoolchild: 0,
                escort: 0,
                driver: 0,
            },
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            // this.records = this.__clone(this.parentRecords);
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- modal -->
        modalOpen() {
            /*            this.resetValidity();
                        this.resetErrors();
                        // <--  -->
                        if (this.form.id > 0) {
                            this.loadEdit();
                            return;
                        }
                        this.loadNew();*/
            this.$bvModal.show(this.$options.name + this.componentKey);
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name + this.componentKey);
            });
        },
    },
    computed: {
        title: {
            get() {
                // let record = this.record;
                // if (this.form.id <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                //     return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                // }
                // // <-- name -->
                // let name = record.meta.name;
                // // <--  -->
                // return this.$tc(this.localeKey, 0) + ': ' + name;
                return this.$tc('adventure.season', 1);
            },
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
