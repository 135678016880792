<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-card
                :header="getName"
                header-bg-variant="secondary"
                header-text-variant="light"
                header-class="h5 font-italic"
                style="width: 800px; height: 600px;"
                class=""
                no-body
            >
                <!-- header -->
                <template #header>
                    <div class="d-flex justify-content-between  align-items-center">
                        <b-button-close @click="close()" class="mr-2"/>
                        {{getName}}
                    </div>
                </template>
                <!-- body -->
                <b-card-body class="d-flex mr-1 p-0">
                    <!-- left buttons -->
                    <div class="my-0 py-2 px-2 d-flex flex-column overflow-auto"
                         style="background: whitesmoke; width: 180px;"
                    >
                        <!-- destinations button -->
                        <b-button
                            class="mt-1"
                            :variant="hotLink.experienceId === null ? 'primary' : 'secondary'"
                            style=""
                            size="sm"
                            @click="updateHotLInk(null)"
                        >
                            {{$tc('adventure.destination.main', 0)}}
                        </b-button>
                        <!-- experiences buttons -->
                        <div class="my-1"/>
                        <b-button
                            v-for="experience in getExperiences"
                            class="mt-1"
                            :variant="hotLink.experienceId === experience.record.id ? 'primary' : 'secondary'"
                            size="sm"
                            @click="updateHotLInk(experience.record.id)"
                        >
                            {{experience.title}}
                        </b-button>
                    </div>
                    <!-- content -->
                    <div class="my-2 mx-2" style="width: 100%">
                        <!-- destinations -->
                        <element-popup-destination
                            v-show="hotLink.experienceId === null"
                            :record="record"
                            :data="data"
                        ></element-popup-destination>
                        <!-- experiences -->
                        <element-popup-experience
                            v-if="getExperiences.length > 0 && hotLink.experienceId > 0"
                            :record="getExperienceRecord"
                        ></element-popup-experience>
                    </div>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import Vue from "vue";
import ElementPopupDestination from "@/components/_public/_adventure/popup/ElementPopupDestination";
import ElementPopupExperience from "@/components/_public/_adventure/popup/ElementPopupExperience";

export default {
    name: "PopupDestination",
    components: {ElementPopupExperience, ElementPopupDestination},
    props: {
        /**
         * @vuese
         * Hot link among Adventure, siderExperience and LeafletMap
         */
        hotLink: {
            type: Object,
            default() {
                return {}
            }
        },
        /**
         * @vuese
         * Destination id. On change the destinations and experiences data is loaded.
         */
        destinationId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            contentSelect: null,
            /**
             * @vuese
             * Turn on/off overlay when process is busy.
             */
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getDestination: `api/v1/_public/get_destination`,
            },
            /**
             * @vuese
             * Data from database
             */
            record: {},
            // <--  -->
            slide: 0,
            sliding: null,
            value: '',
            experienceDate: null,
            experienceGuests: null,
            guests: {
                adult: 0,
                child: 0,
                toddler: 0,
                senior: 0,
                schoolchild: 0,
                escort: 0,
            },
            messageEmail: '',
            messageText: '',
            // <-- modal experienceBooking -->
            modalBus: new Vue(),
            // <-- base -->
            data: {},
        }
    },
    mounted() {
    },
    methods: {
        close() {
            /**
             * @vuese
             * Trigger for closing popup window.
             */
            this.$emit('eventPopupClose');
        },
        /**
         * @vuese
         * Show/hide overlap when a process is busy
         * @arg boolean
         */
        showOverlay(show) {
            this.overlay.opacity = show ? 0.7 : 1;
            this.overlay.visible = show;
        },
        /**
         * @vuese
         * Load data from database
         */
        loadDataFromDatabase() {
            this.showOverlay(true);
            // <-- clear old data -->
            this.$set(this, 'record', {});
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getDestination + `/${this.destinationId}`, config)
                .then((response) => {
                    this.$set(this, 'record', response.data.record);
                    this.$nextTick(() => {
                        this.showOverlay(false);
                    });
                })
                .catch((error) => {
                    console.log("ERROR:", error.response.data);
                });
        },
        /**
         * @vuese
         * Update hot link values
         */
        updateHotLInk(experienceId) {
            if (experienceId === null) {
                this.hotLink.destinationId = this.destinationId;
                this.hotLink.experienceId = null;
                return;
            }
            this.hotLink.destinationId = null;
            this.hotLink.experienceId = experienceId;
        },
    },
    computed: {
        // <-- destinations -->
        getName() {
            if (this.__isNull(this.record.meta) || this.__isNull(this.record.locale)) {
                return '...';
            }
            // <--  -->
            let name = this.record.meta.name;
            if (!this.__isNull(this.record.locale.name)) {
                name = this.record.locale.name;
            }
            // <--  -->
            // name += this.__isNull(this.record.locale.subtitle) ? '' : ':';
            return name;
        },
        getCarouselPictures() {
            if (this.__isNull(this.data.pictures_order) || this.data.pictures_order.length === 0) {
                return [
                    {
                        caption: null,
                        src: this.__getDefaultImage
                    }
                ];
            }
            let carousel = [];
            // <-- src -->
            let caption = null;
            this.data.pictures_order.forEach((value, index) => {
                if (value.publish) {
                    this.data.pictures.find((picture) => {
                        if (picture.key === value.key) {
                            caption = picture.meta.name;
                            // <-- locale -->
                            if (picture.locale !== null && picture.locale[this.$i18n.locale] !== undefined) {
                                if (!this.__isNull(picture.locale[this.$i18n.locale].name)) {
                                    caption = picture.locale[this.$i18n.locale].name;
                                }
                            }
                            carousel.push(
                                {
                                    caption: caption,
                                    src: this.__getImageSrc(picture.image.src)
                                }
                            );
                        }
                    });
                }
            });
            return carousel;
        },
        getSubtitle() {
            if (this.__isNull(this.record.meta) || this.__isNull(this.record.locale)) {
                return null;
            }
            // <--  -->
            let subtitle = this.record.meta.subtitle;
            if (!this.__isNull(this.record.locale.subtitle)) {
                subtitle = this.record.locale.subtitle;
            }
            // <--  -->
            return subtitle;
        },
        getDescription() {
            if (this.__isNull(this.record.locale)) {
                return null;
            }
            return this.record.locale.description;
        },
        getHost() {
            if (this.__isNull(this.record.locale)) {
                return null;
            }
            return this.record.locale.host;
        },
        getNotes() {
            if (this.__isNull(this.record.locale)) {
                return null;
            }
            return this.record.locale.notes;
        },
        // <-- experiences -->
        getExperiences() {
            if (this.__isNull(this.record.experiences)) {
                return [];
            }
            // <--  -->
            let array = [];
            let name;
            this.record.experiences.forEach((experience, index) => {
                // <-- name -->
                name = !this.__isNull(experience.locale.name) ? experience.locale.name : experience.meta.name;
                array.push({
                    title: name,
                    record: experience
                });
            });
            // <--  -->
            return array;
        },
        getExperienceRecord() {
            let record = {};
            this.getExperiences.every(experience => {
                if (experience.record.id === this.hotLink.experienceId) {
                    record = experience.record;
                    return false;
                }
                return true;
            });
            // <--  -->
            return record;
        },
    },
    filters: {},
    watch: {
        'destinationId': {
            handler: function (destinationId) {
                if (destinationId > 0) {
                    this.loadDataFromDatabase();
                }
            },
            deep: true
        },
        'hotLink.experienceId': {
            handler: function (value, oldValue) {

            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
