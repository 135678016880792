<template>
    <div class="overflow-auto" style="max-height: 530px">
        <b-row no-gutters v-if="!__isNull(this.record)">
            <b-col lg="12" xl="12">
                <b-row>
                    <!-- experience -->
                    <b-col class="px-3 bg-white" cols="12" lg="12" md="12" sm="12" xl="6">
                        <!-- name -->
                        <h1>{{getName}}</h1>
                        <!-- subtitles -->
                        <h5 class="text-secondary" style="font-size: medium">{{getSubtitle}}</h5>
                        <!-- experience rating -->
                        <b-card-text class="text-primary my-1">
                            <div class="rating-stars">
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <b-icon-star-fill variant="danger"></b-icon-star-fill>
                                <!--                                            <b-icon-star-half></b-icon-star-half>-->
                                <!--                                            <b-icon-star></b-icon-star>-->
                            </div>
                            <div class="rating-reviews mb-3">
                                (21 reviews)
                            </div>
                        </b-card-text>
                        <!-- experiences categories/types -->
                        <b-card-text class="text-primary my-1" style="clear: both">
                                    <span v-for="categoryTypes in getCategoriesTypes">
                                        <!-- categories -->
                                        <b-badge variant="secondary">
                                            {{categoryTypes.category}}
                                        </b-badge>
                                        <!-- types -->
                                        <b-badge v-for="(type, index) in categoryTypes.types" variant="primary"
                                                 :class="index === categoryTypes.types.length-1 ? 'ml-1 mr-2' : 'ml-1'"
                                        >
                                            {{type}}
                                        </b-badge>
                                    </span>
                        </b-card-text>
                        <!-- languages -->
                        <b-card-text class="pt-2 m-0">
                            <div>
                                <b class="text-secondary">{{$tc('adventure.tour_language', 1)}}:</b>
                                <span v-for="(language, index) in getLanguages">
                                            <i v-if="index >= getLanguages.length -1">
                                                 {{`${language}`}}
                                            </i>
                                            <i v-else>
                                                 {{`${language},`}}
                                            </i>
                                        </span>
                            </div>
                        </b-card-text>
                        <!-- suitable for -->
                        <b-card-text class="pt-2 m-0">
                            <div>
                                <b class="text-secondary">{{$t('adventure.suitable_for')}}: </b>
                                <span v-for="(suitable, index) in getSuitable">
                                        <i v-if="index >= getSuitable.length -1">
                                             {{`${suitable}`}}
                                        </i>
                                        <i v-else>
                                             {{`${suitable},`}}
                                        </i>
                                    </span>
                            </div>
                        </b-card-text>
                        <!-- participants number -->
                        <b-card-text class="pt-2 m-0">
                            <div>
                                <b class="text-secondary">{{$t('adventure.participants_number')}}:</b>
                                {{getParticipantsNumber}}
                            </div>
                        </b-card-text>
                        <!-- duration -->
                        <b-card-text class="pt-2 m-0">
                            <div>
                                <b class="text-secondary">{{$t('adventure.duration')}}:</b>
                                {{getDuration}}
                            </div>
                        </b-card-text>
                        <!-- seasons -->
                        <b-card-text class="pt-1 m-0">
                            <div>
                                <b class="text-secondary">{{$tc('adventure.season', 0)}}:</b>
                                {{getSeason}}
                            </div>
                        </b-card-text>
                        <!-- experience booking -->
                        <div class="py-2 experience-booking">
                            <b-row>
                                <!--                                            <b-col cols="6">
                                                                                <b-dropdown
                                                                                    id="guests"
                                                                                    class="m-md-2"
                                                                                    variant="primary"
                                                                                    size="sm"
                                                                                    style="width: 100%"
                                                                                >
                                                                                    <template #button-content>
                                                                                        <b-icon-calendar class="mr-1"></b-icon-calendar>
                                                                                        {{$t('adventure.experience.date')}}
                                                                                    </template>
                                                                                    <b-dropdown-item>
                                                                                        <b-calendar
                                                                                            v-model="experienceDate"
                                                                                            locale="sl-SI"
                                                                                        >
                                                                                        </b-calendar>
                                                                                    </b-dropdown-item>
                                                                                </b-dropdown>
                                                                            </b-col>
                                                                            <b-col cols="6" class="mt-2">
                                                                                <div>
                                                                                    {{$t('adventure.experience.date_chosen')}}:
                                                                                </div>
                                                                                <div>
                                                                                    <b>{{__convertBaseDateToLocaleDateString(experienceDate)}}</b>
                                                                                </div>
                                                                            </b-col>
                                                                            <b-col cols="6">
                                                                                <b-dropdown
                                                                                    id="guests"
                                                                                    class="m-md-2"
                                                                                    variant="primary"
                                                                                    size="sm"
                                                                                    style="width: 100%"
                                                                                    v-model="experienceGuests"
                                                                                >
                                                                                    <template #button-content>
                                                                                        <b-icon-people-fill class="mr-1"></b-icon-people-fill>
                                                                                        {{$t('adventure.experience.guests')}}
                                                                                    </template>
                                                                                    <b-row style="width: 300px" no-gutters>
                                                                                        <b-col cols="6">
                                                                                            <b-dropdown-form>
                                                                                                {{$tc('adventure.experience.adult', 1)}} (13+):
                                                                                                <b-form-spinbutton
                                                                                                    size="sm"
                                                                                                    id="guestsAdult" v-model="guests.adult" min="0"
                                                                                                >
                                                                                                </b-form-spinbutton>
                                                                                            </b-dropdown-form>
                                                                                            <b-dropdown-form>
                                                                                                {{$tc('adventure.experience.child', 1)}} (3-12):
                                                                                                <b-form-spinbutton
                                                                                                    size="sm"
                                                                                                    id="guestsChild" v-model="guests.child" min="0"
                                                                                                >
                                                                                                </b-form-spinbutton>
                                                                                            </b-dropdown-form>
                                                                                            <b-dropdown-form>
                                                                                                {{$tc('adventure.experience.toddler', 1)}} (0-2):
                                                                                                <b-form-spinbutton
                                                                                                    size="sm"
                                                                                                    id="guestsToddler" v-model="guests.toddler" min="0"
                                                                                                >
                                                                                                </b-form-spinbutton>
                                                                                            </b-dropdown-form>
                                                                                        </b-col>
                                                                                        <b-col cols="6">
                                                                                            <b-dropdown-form>
                                                                                                {{$tc('adventure.experience.senior', 1)}}:
                                                                                                <b-form-spinbutton
                                                                                                    size="sm"
                                                                                                    id="guestsSenior" v-model="guests.senior" min="0"
                                                                                                >
                                                                                                </b-form-spinbutton>
                                                                                            </b-dropdown-form>
                                                                                            <b-dropdown-form>
                                                                                                {{$tc('adventure.experience.schoolchild', 1)}}:
                                                                                                <b-form-spinbutton
                                                                                                    size="sm"
                                                                                                    id="guestsSchoolchild" v-model="guests.schoolchild"
                                                                                                    min="0"
                                                                                                >
                                                                                                </b-form-spinbutton>
                                                                                            </b-dropdown-form>
                                                                                            <b-dropdown-form>
                                                                                                {{$tc('adventure.experience.escort', 1)}}:
                                                                                                <b-form-spinbutton
                                                                                                    size="sm"
                                                                                                    id="guestsEscort" v-model="guests.escort" min="0"
                                                                                                >
                                                                                                </b-form-spinbutton>
                                                                                            </b-dropdown-form>
                                                                                        </b-col>
                                                                                    </b-row>
                                                                                    &lt;!&ndash;                                                    <b-dropdown-divider></b-dropdown-divider>&ndash;&gt;
                                                                                    &lt;!&ndash;                                                    <b-dropdown-item active>Active action</b-dropdown-item>&ndash;&gt;
                                                                                    &lt;!&ndash;                                                    <b-dropdown-item disabled>Disabled action</b-dropdown-item>&ndash;&gt;
                                                                                </b-dropdown>
                                                                            </b-col>
                                                                            <b-col cols="6" class="mt-2">
                                                                                {{$t('adventure.experience.guests_chosen')}}
                                                                                <b>{{guests.adult}} adults, {{guests.child}} children,
                                                                                    {{guests.toddler}} toddlers</b>
                                                                            </b-col>-->
                                <!-- add experience to adventure -->
                                <b-col cols="12" class="text-center mt-2">
                                    <b-button
                                        variant="secondary"
                                        :title="$t('adventure.experience.add_title')"
                                        style="width: 80%"
                                        size="md"
                                        @click="openModalExperienceBooking()"
                                    >
                                        <!--                                            @click="addExperienceToAdventure(marker)"-->
                                        <b-icon-check-circle-fill variant="info"></b-icon-check-circle-fill>
                                        {{$t('adventure.experience.add_button')}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <!-- carousel pictures -->
                    <b-col class="pr-3 py-0 pl-0" cols="12" lg="12" md="12" sm="12" xl="6">
                        <b-carousel
                            id="experience-carousel"
                            class="img-container"
                            background="#ababab"
                            v-model="slide"
                            :interval="0"
                            controls
                            fade
                            indicators
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                        >
                            <b-carousel-slide
                                v-for="carousel in getPictures"
                                class="provider-carousel"
                                :img-src="carousel.src"
                            ></b-carousel-slide>
                        </b-carousel>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- experience tabs -->
        <b-row class="mt-2">
            <b-col>
                <b-card class="mb-2" no-body>
                    <b-tabs card fill pills>
                        <!-- tab story -->
                        <b-tab class="destination-tabs">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    :icon="!__isNull(getStory) ? 'b-icon-book-fill' : 'b-icon-book'"
                                ></b-icon>
                                {{$t('adventure.experience.tab.story')}}
                            </template>
                            <div class="p-0" v-html="getStory"></div>
                        </b-tab>
                        <!-- tab mission -->
                        <b-tab class="destination-tabs">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    :icon="!__isNull(getMission) ? 'b-icon-trophy-fill' : 'b-icon-trophy'"
                                ></b-icon>
                                {{$t('adventure.experience.tab.mission')}}
                            </template>
                            <div class="p-0" v-html="getMission"></div>
                        </b-tab>
                        <!-- tab conditions -->
                        <b-tab class="destination-tabs">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    :icon="!getConditions.isNull ? 'b-icon-exclamation-triangle-fill' : 'b-icon-exclamation-triangle'"
                                ></b-icon>
                                {{$t('adventure.experience.tab.conditions')}}
                            </template>
                            <!-- age limits -->
                            <div v-if="getConditions.minAge !== null || getConditions.maxAge !== null">
                                <div class="font-weight-bold" style="font-size: larger">
                                    {{$t('adventure.age_limits')}}
                                </div>
                                <!-- min age -->
                                <div class="mt-1">
                                    <b>{{$t('adventure.min_age')}}: </b>
                                    <span>{{getConditions.minAge}}</span>
                                </div>
                                <!-- max age -->
                                <div class="mt-1">
                                    <b>{{$t('adventure.max_age')}}: </b>
                                    <span>{{getConditions.maxAge}}</span>
                                </div>
                            </div>
                            <!-- condition notes -->
                            <div v-if="getConditions.notes !== null">
                                <div class="mt-2 font-weight-bold" style="font-size: larger">
                                    {{$t('adventure.other_condition')}}
                                </div>
                                <div class="mt-1">
                                    <span class="p-0" v-html="getConditions.notes"></span>
                                </div>
                            </div>
                        </b-tab>
                        <!-- tab included -->
                        <b-tab class="destination-tabs">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    :icon="!__isNull(getIncluded) ? 'b-icon-gift-fill' : 'b-icon-gift'"
                                ></b-icon>
                                {{$t('adventure.experience.tab.included')}}
                            </template>
                            {{getIncluded}}
                        </b-tab>
                        <!-- tab opening hours -->
                        <b-tab class="destination-tabs">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    :icon="!__isNull(getOpeningHours) ? 'b-icon-door-open-fill' : 'b-icon-door-open'"
                                ></b-icon>
                                {{getOpeningHours.tabTitle}}
                            </template>
                            <!-- opening hours -->
                            <div class="mt-2" :class="day.isClosed ? 'text-danger': 'text-secondary'"
                                 v-for="(day, index) in getOpeningHours.openingHours">
                                <!-- day of week: name -->
                                <b-row align-v="baseline">
                                    <b-col cols="3" style="font-size: larger; font-weight: bolder;">
                                        {{day.dayOfWeekName}}
                                    </b-col>
                                    <!-- only low season -->
                                    <b-col v-if="day.onlyLowSeason">
                                        {{day.seasonsHours[0].hours[0].from}}-{{day.seasonsHours[0].hours[0].to}}
                                    </b-col>
                                    <!-- closed -->
                                    <b-col v-if="day.isClosed">
                                        {{$t('adventure.closed')}}
                                    </b-col>
                                </b-row>
                                <!-- seasons: (low season + seasons), hours -->
                                <b-row class="mb-1" v-for="(seasons, index) in day.seasonsHours"
                                       v-if="!day.onlyLowSeason && !day.isClosed"
                                       align-v="baseline"
                                >
                                    <!-- season (low season + seasons) -->
                                    <b-col class="mt-1" cols="3" v-if="seasons.hours.length > 0">
                                        <div style="font-weight: bolder">
                                            {{index === 0 ? seasons.season.title : seasons.season.label}}
                                        </div>
                                    </b-col>
                                    <!-- hours -->
                                    <b-col cols="2" v-for="hour in seasons.hours" v-if="seasons.hours.length > 0">
                                        {{hour.from}}-{{hour.to}}
                                    </b-col>
                                </b-row>
                                <hr class="mt-0 my-1" v-if="index < getOpeningHours.openingHours.length-1">
                            </div>
                        </b-tab>
                        <!-- tab price list -->
                        <b-tab class="destination-tabs" v-if="getPriceListItems.length > 0">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    :icon="'b-icon-list'"
                                    :variant="!__isNull(getPriceListItems) ? 'secondary' : 'primary'"
                                ></b-icon>
                                {{$t('adventure.experience.tab.price_list')}}
                            </template>
                            <b-table
                                :fields="getPriceListColumns"
                                :items="getPriceListItems"
                                bordered head-row-variant="dark" hover outlined
                                responsive
                                striped
                                table-variant="light"
                                thead-class="text-center"
                                tbody-class="text-center"
                            >
                            </b-table>
                        </b-tab>
                        <!-- tab reviews -->
                        <b-tab class="destination-tabs">
                            <template #title>
                                <b-icon
                                    variant="danger"
                                    class="mr-1"
                                    :icon="'b-icon-star-fill'"
                                ></b-icon>
                                {{$t('adventure.experience.tab.reviews')}}
                            </template>
                            <!--                                         <div class="p-0" v-html="getReviews">
                                                                     </div>-->
                            <h5 class="text-primary">
                                #Milan (Polska)

                                <b-icon-star-fill></b-icon-star-fill>
                                <b-icon-star-fill></b-icon-star-fill>
                                <b-icon-star-fill></b-icon-star-fill>
                                <b-icon-star-fill></b-icon-star-fill>
                                <b-icon-star-fill></b-icon-star-fill>
                            </h5>
                            #I was very surprised by the experience. Nice people, I recommend it to adults,
                            families and children!
                        </b-tab>
                        <!-- tab notes -->
                        <b-tab class="destination-tabs" v-if="!__isNull(getNotes)">
                            <template #title>
                                <b-icon
                                    class="mr-1"
                                    icon="b-icon-file-earmark-text-fill"
                                ></b-icon>
                                {{$t('adventure.destination.tab.notes')}}
                            </template>
                            <div class="p-0" v-html="getNotes">
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
        <!-- modalFormXxx -->
        <modal-experience-booking
            :component-key="componentKey"
            :modal-bus.sync="modalBus"
        ></modal-experience-booking>
    </div>
</template>

<script>
import Vue from "vue";
import ModalExperienceBooking from "@/components/_public/_adventure/modal/ModalExperienceBooking";

//@group Form.ADVENTURE
export default {
    name: "ElementPopupExperience",
    components: {ModalExperienceBooking},
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            componentKey: null,
            slide: 0,
            // <-- priceList -->
            priceListColumns: [
                {
                    key: 'price_unit_id',
                    label: this.$t('adventure.per_unit'),
                    sortable: false,
                    show: true,
                },
                {
                    key: 'age',
                    label: this.$t('adventure.age'),
                    sortable: false,
                    show: true,
                },
            ],
            // <-- modal experienceBooking -->
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.componentKey = this.__randomKey();
    },
    methods: {
        // <-- carousel -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        // <-- experienceBooking -->
        openModalExperienceBooking() {
            this.modalBus.$emit('modalOpen', 0);
        },
        addExperienceToAdventure(marker) {
            // <-- get select if experience is already in adventure -->
            let select = false;
            this.adventures.every(adventure => {
                if (adventure.id === marker.id) {
                    select = adventure.select;
                    return false;
                }
                return true;
            });
            // <-- add experience to adventure -->
            this.adventures.push(
                {
                    key: this.__randomKey(),
                    select: select,
                    id: marker.id,
                    title: marker.title,
                    description: marker.description,
                    // experienceId: marker.experience.id
                }
            );
        },
        // <--  -->
        getPriceListSeasonByKey(key) {
            let record = this.record.content;
            const dateOptions = {month: 'long', day: 'numeric'};
            // <--  -->
            return record.priceListSeasons.find((n) => {
                if (n.key === key) {
                    if (key !== 'default') {
                        n.label = `${this.__convertBaseDateToLocaleDateString_options(n.start, dateOptions)} - ${this.__convertBaseDateToLocaleDateString_options(n.end, dateOptions)}`;
                        return n;
                    }
                    n.title = this.$t("adventure.low_season");
                    return n;
                }
            });
            // <--  -->
            return null;
        },
    },
    computed: {
        getName() {
            return !this.__isNull(this.record.locale.name) ? this.record.locale.name : this.record.meta.name;
        },
        getSubtitle() {
            // if (this.__isNull(this.record)) {
            //     return null;
            // }
            return !this.__isNull(this.record.meta.subtitle) ? this.record.locale.subtitle : this.record.meta.subtitle;
        },
        getLanguages() {
            if (this.__isNull(this.record.languagesTour)) {
                return null;
            }
            return this.record.languagesTour;
        },
        getStory() {
            if (this.__isNull(this.record)) {
                return null;
            }
            // <--  -->
            return !this.__isNull(this.record.locale.story) ? this.__removeParagrafFromText(this.record.locale.story) : null;
        },
        getCategoriesTypes() {
            if (this.__isNull(this.record.experienceTypes)) {
                return null;
            }
            let record = this.record.experienceTypes;
            // <-- categories -->
            let categories = [];
            record.forEach(value => {
                categories.push(value.categoryName);
            });
            categories = this.__uniqueArray(categories);
            categories = this.__sortArray(categories);
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                record.forEach(record => {
                    if (category === record.categoryName) {
                        types.push(record.name);
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <--  -->
            return categoriesTypes;
        },
        getSuitable() {
            if (this.__isNull(this.record.suitable)) {
                return null;
            }
            return this.record.suitable;
        },
        getParticipantsNumber() {
            if (this.__isNull(this.record.content)) {
                return null;
            }
            // <--  -->
            let min = this.record.content.participants_min;
            let max = this.record.content.participants_max;
            // <--  -->
            if (parseInt(min) === parseInt(max)) {
                return max;
            }
            return min + '–' + max;
        },
        getDuration() {
            if (this.__isNull(this.record.content)) {
                return null;
            }
            let day = this.record.content.duration.days;
            let time = this.record.content.duration.time;

            // <-- remove leader 0 in time -->
            if (!this.__isNull(time) && time.substring(0, 1) === '0') {
                time = time.substring(1);
            }
            // <--  -->
            let duration = day > 0 ? day + ' ' + this.$tc('date_time.day', day === 1 ? 1 : 0).toLowerCase() : '';
            duration += time === null ? '' : ' ';
            duration += time === null ? '' : time + ' h';
            // <--  -->
            return duration;
        },
        getSeason() {
            if (this.__isNull(this.record.content)) {
                return null;
            }
            // <--  -->
            let start = this.record.content.season.start;
            let end = this.record.content.season.end;
            // <--  -->
            if (this.__isNull(start) && this.__isNull(end)) {
                return this.$t('date_time.whole_year');
            }
            return (!this.__isNull(start) ? this.__convertBaseDateToLocaleDateString(start) : '') + '–' + (!this.__isNull(end) ? this.__convertBaseDateToLocaleDateString(end) : '');
        },
        getMission() {
            if (this.__isNull(this.record.locale) || this.__isNull(this.record.locale.mission)) {
                return null;
            }
            return this.__removeParagrafFromText(this.record.locale.mission);
        },
        getConditions() {
            if (this.__isNull(this.record.content) || this.__isNull(this.record.locale)) {
                return {
                    isNull: true,
                    minAge: null,
                    maxAge: null,
                    notes: null
                };
            }
            // <--  -->
            let minAge = this.record.content.conditions.minAge;
            let maxAge = this.record.content.conditions.maxAge;
            let notes = this.record.locale.conditionNotes === undefined ? null : this.record.locale.conditionNotes;

            let notesText = null;
            if (!this.__isNull(notes)) {
                // <-- remove html tags -->
                notesText = notes.replace(/(<([^>]+)>)/ig, '');
                // <-- remove spaces -->
                notesText = notesText.replace(/\s/g, '');
            }
            // <--  -->
            return {
                isNull: this.__isNull(minAge) && this.__isNull(maxAge) && this.__isNull(notesText),
                minAge: minAge,
                maxAge: maxAge,
                notes: this.__isNull(notesText) ? null : this.__removeParagrafFromText(notes)
            };
        },
        getIncluded() {
            if (this.__isNull(this.record.locale) || this.__isNull(this.record.locale.included)) {
                return null;
            }
            return this.record.locale.included;
        },
        getOpeningHours() {
            if (this.__isNull(this.record.content) || this.__isNull(this.record.content.openingHours)) {
                return {
                    tabTitle: null,
                };
            }
            let record = this.record.content;
            // <-- opening hours -->
            let openingHours = [];
            record.openingHours.byDays.forEach(day => {
                // <-- seasons -->
                let seasonHours = [];
                let hoursCount = 0;
                Object.keys(day.seasonHours).forEach(key => {
                    // <-- hours -->
                    let hours = [];
                    day.seasonHours[key].forEach(hour => {
                        if (!this.__isNull(hour.from) && !this.__isNull(hour.to))
                            hours.push(hour);
                    });
                    hoursCount += hours.length;
                    seasonHours.push(
                        {
                            season: this.getPriceListSeasonByKey(key),
                            hours: hours
                        }
                    );
                });
                openingHours.push(
                    {
                        isClosed: hoursCount === 0,
                        onlyLowSeason: hoursCount === 1,
                        dayOfWeekName: day.dayOfWeekName,
                        seasonsHours: seasonHours,
                    }
                );
            });
            // <--  -->
            return {
                tabTitle: record.type == 'sessions' ? this.$tc('adventure.experience.tab.session', 0) : this.$t('adventure.experience.tab.opening_hours'),
                openingHours: openingHours,
            };
        },
        getNotes() {
            if (this.__isNull(this.record.locale) || this.__isNull(this.record.locale.notes)) {
                return null;
            }
            return this.__removeParagrafFromText(this.record.locale.notes);
        },
        getPictures() {
            let array = [];
            let caption;
            this.record.pictures.forEach(picture => {
                array.push(
                    {
                        caption: !this.__isNull(picture.locale) && !this.__isNull(picture.locale.name) ? picture.locale.name : picture.meta.name,
                        src: this.__getImageSrc(picture.image.src)
                    }
                );
            });
            // <--  -->
            return array;
        },
        // <-- priceList -->
        getPriceListColumns() {
            if (this.__isNull(this.record.content) || this.__isNull(this.record.content.priceList)) {
                return null;
            }
            // <--  -->
            let priceList = this.record.content.priceList;
            let columns = this.priceListColumns;
            // <--  -->
            let values = [];
            columns.forEach(column => {
                values.push({
                    key: column.key,
                    label: column.label,
                    formatter: column.formatter,
                    sortable: column.sortable,
                    show: column.show,
                });
            });
            // <-- add columns seasons -->
            let dateStart, dateEnd;
            let dateStartString, dateEndString;
            priceList.forEach(season => {
                Object.keys(season.seasonsPrices).forEach(key => {
                    // <-- default season -->
                    if (key === 'default') {
                        values.push({
                            key: key,
                            label: this.$t(`adventure.low_season`),
                        });
                    }
                    // <-- date start -->
                    else {
                        // <--  -->
                        values.push({
                            key: key,
                            label: this.getPriceListSeasonByKey(key).label,
                        });
                    }
                });
            });
            // <--  -->
            return values;
        },
        getPriceListItems() {
            if (this.__isNull(this.record.content) || this.__isNull(this.record.content.priceList)) {
                return [];
            }
            // <--  -->
            let priceList = this.record.content.priceList;
            let values = [];
            // <--  -->
            priceList.forEach((value, index) => {
                if (value.price_unit !== null) {
                    // <-- minAge, maxAge -->
                    let ageMin = value.price_unit.minAge;
                    let ageMax = value.price_unit.maxAge;
                    let age = null;
                    if (ageMin !== null && ageMax === null) {
                        // age = `do ${minAge} let`
                        age = this.$t('adventure.age_under', {age_min: ageMin});
                    } else if (ageMin === null && ageMax !== null) {
                        age = this.$t('adventure.age_over', {age_max: ageMax});
                    } else if (ageMin !== null && ageMax !== null) {
                        age = this.$t('adventure.age_between', {age_min: ageMin, age_max: ageMax});
                    }
                    // <--  -->
                    values.push(
                        {
                            price_unit_id: value.price_unit.name,
                            age: age,
                        }
                    );
                    Object.keys(value.seasonsPrices).forEach(key => {
                        values[index][key] = `${this.__getDecimalLocale(value.seasonsPrices[key])} ${value.currency}`;
                    });
                }
            });
            // <--  -->
            return values;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
